(function() {
    'use strict';

    angular.module('app').controller('NavMenu', NavMenu);

    function NavMenu() {
        console.log('Nav Menu accessed');
        var nm = this;
        nm.isMenuOpen = false;
        nm.currentPage = 1;
        nm.activeSubMenu = "";
        nm.toggleMenu = function() {
            nm.isMenuOpen = !nm.isMenuOpen;
        };
        nm.changePage = function(pageNumber) {
            nm.currentPage = pageNumber;
        };
        nm.openSubMenu = function(submenu) {
            if(nm.activeSubMenu) {
                nm.activeSubMenu = "";
            } else {
                nm.activeSubMenu = submenu;
            }
        }
        
    }
})();
