(function() {
    'use strict';

    angular.module('app').factory('featureService', function() {

        return {
            getFeature: function getFeature(feature) {
                return feature;
            }
        }
    });
})();
