(function() {
    'use strict';

    angular.module('app').controller('FeatureSwitcher', FeatureSwitcher);

    function FeatureSwitcher(featureService) {
        var fs = this;
        fs.currentFeature = "phone";
        fs.switchFeature = function switchFeature(feature) {
            var newFeature = featureService.getFeature(feature);
            fs.currentFeature = newFeature;
        }
    }
})();
